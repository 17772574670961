import { useEffect, useState, useCallback } from 'react';

import useDate from '../context/useDate';

const useModal = (getPaginated, path, searchType) => {
  const { date, setDate, today } = useDate();

  const [rows, setRows] = useState([{}]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [editData, setEditData] = useState(false);
  const [search, setSearch] = useState(searchType === 'date' ? date : '');
  const [sort, setSort] = useState(null);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [fetchError, setFetchError] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSearch = (search) => {
    setSearch(search);
    if (searchType === 'date') setDate(search);
  };

  const handleClose = () => {
    setEditData(false);
    setError(null);
    setOpen(false);
  };

  const handleEdit = (data) => {
    setEditData(data);
    setOpen(true);
  };

  const handleEditData = (data) => {
    setEditData(data);
  };

  const handlePage = (page) => {
    setPage(page);
  };

  const handlePerPage = (perPage) => {
    setPerPage(perPage);
  };

  const handleError = (error) => {
    setError(error);
  };

  const handleSort = (data) => {
    setSort(sort ? null : data);
  };

  const fetch = useCallback(async () => {
    try {
      setFetchError(false);
      setLoading(true);
      const data = await getPaginated(page, perPage, search, sort);
      setCount(search ? data.count : data.count ? data.totalCount : 0);
      setRows(data[path]);
      setLoading(false);
    } catch (error) {
      setFetchError(true);
      throw error;
    }
  }, [page, perPage, search, sort, getPaginated, path]);

  const handleRequest = async (values, edit, create) => {
    try {
      if (editData) return await edit(values);
      return await create(values);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    setPage(1);
  }, [search, perPage]);

  return {
    today,
    page,
    perPage,
    loading,
    rows,
    count,
    open,
    error,
    fetchError,
    search,
    editData,
    handleOpen,
    handleClose,
    handleEdit,
    handleEditData,
    handleRequest,
    handleSearch,
    handleSort,
    handlePage,
    handlePerPage,
    handleError,
    fetch,
  };
};

export default useModal;
