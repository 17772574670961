import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import SideBar from '../Sidebar';

import styles from './Layout.module.css';

const Layout = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.box}>
      <div className={open ? styles.sideBarOpen : styles.sideBarClosed}>
        <SideBar open={open} handleOpen={setOpen} />
      </div>

      <main className={open ? styles.contentOpen : styles.contentClosed}>
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
