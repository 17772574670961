import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormikContext, useField, ErrorMessage } from 'formik';

import getStyle from './getStyle';

import styles from './TextBox.module.css';

const TextBox = ({ label, name, type = 'string', max = null }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const [isFocus, setIsFocus] = useState(false);
  const [textBoxStyles, setTextBoxStyles] = useState({
    border: 'borderDefault',
    label: field.value ? 'labelFocus' : 'labelDefault',
  });

  useEffect(() => {
    const styles = getStyle(
      meta.error,
      meta.touched,
      isFocus,
      field.value,
      type
    );

    setTextBoxStyles(styles);
  }, [meta.error, meta.touched, isFocus, field.value, type]);

  const textBoxConfig = {
    ...field,
    className: styles.input,
    autoComplete: 'new-password', // Temp: use :-webkit-autofill and :autofill to fix
    autoCorrect: 'off',
    input: 'text',
  };

  return (
    <>
      <div>
        <div
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          className={styles[textBoxStyles.border]}
        >
          <input
            {...textBoxConfig}
            onChange={(e) => setFieldValue(name, e.target.value)}
            className={field.value ? styles.inputText : styles.input}
            type={type}
            max={max}
          />
          <span className={styles[textBoxStyles.label]}>{label}</span>
        </div>

        {meta.error && meta.touched && (
          <div className={styles.error}>
            <ErrorMessage name={field.name} />
          </div>
        )}
      </div>
    </>
  );
};

TextBox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['string', 'number', 'date', 'password']),
  max: PropTypes.string,
};

export default TextBox;
