import PropTypes from 'prop-types';

import styles from './Overlay.module.css';

const Overlay = ({ onClick }) => {
  return <div onClick={onClick} className={styles.overlay}></div>;
};

Overlay.propTypes = {
  onClick: PropTypes.func,
};

export default Overlay;
