import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useField, useFormikContext, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';

import OptionsList from '../OptionsList';

import useAuth from '../../../context/useAuth';

import styles from './Autocomplete.module.css';

const Autocomplete = ({
  name = '',
  label,
  getAll,
  fieldText,
  path,
  constantOptions,
  query,
  size = 'sm',
  handleChange,
  sort = {},
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  const { setUser } = useAuth();

  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    (async function () {
      try {
        if (!constantOptions) {
          const res = await getAll(query, sort);
          setOptions(res[path]);
          setLoading(false);
        } else {
          setOptions(constantOptions);
          setLoading(false);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          setUser(null);
          toast.error('La sesión ha expirado por inactividad.');
        } else {
          toast.error('Se ha producido un error.');
        }
      }
    })();
  }, [getAll, query, constantOptions, path, setUser, sort]);

  let borderStyle = 'borderDefault';
  let labelStyle = 'labelDefault';

  const autocompleteConfig = {
    ...field,
    className: styles.input,
    autoComplete: 'off',
    autoCorrect: 'off',
    input: 'text',
  };

  if (
    (meta.error && meta.touched && !isFocus) ||
    (search ? filteredOptions.length <= 0 : options.length <= 0 && !loading)
  ) {
    borderStyle = 'borderError';
    if (isFocus || field.value || search) {
      labelStyle = 'labelErrorText';
    } else {
      labelStyle = 'labelErrorNoText';
    }
  } else if (isFocus || field.value || search) {
    borderStyle = 'borderFocus';
    labelStyle = 'labelFocus';
  } else {
    borderStyle = 'borderDefault';
    labelStyle = 'labelDefault';
  }

  const handleSearch = (search) => {
    const filtered = options.filter((option) =>
      option[fieldText].includes(search.toUpperCase())
    );

    setSearch(search);
    setFilteredOptions(filtered);
    setFieldValue(name, '');
  };

  const handleSelectedOption = (index) => {
    if (handleChange) handleChange();
    setFieldValue(name, search ? filteredOptions[index] : options[index]);

    setSearch('');
    setFilteredOptions([]);
    setIsFocus(false);
  };

  const handleClose = () => {
    setSearch('');
    setIsFocus(false);
  };

  return (
    <>
      <div className={styles.group} onFocus={() => setIsFocus(true)}>
        <div className={styles[borderStyle]}>
          <input
            {...autocompleteConfig}
            onChange={(e) => handleSearch(e.target.value)}
            className={styles.input}
            value={field.value ? field.value[fieldText] : search}
          />

          <span className={styles[labelStyle]}>{label}</span>
        </div>

        {meta.error && meta.touched && !isFocus && (
          <div className={styles.error}>
            <ErrorMessage name={field.name} />
          </div>
        )}

        {(search ? filteredOptions.length <= 0 : options.length <= 0) &&
          !loading && (
            <div className={styles.error}>
              <p>No se encontraron opciones.</p>
            </div>
          )}

        <OptionsList
          loading={loading}
          size={size}
          handleSelectedOption={handleSelectedOption}
          displayOptions={isFocus}
          handleClose={handleClose}
          fieldText={fieldText}
          options={search ? filteredOptions : options}
        />
      </div>
    </>
  );
};

Autocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  getAll: PropTypes.func,
  fieldText: PropTypes.string.isRequired,
  constantOptions: PropTypes.array,
  path: PropTypes.string,
  query: PropTypes.object,
  handleChange: PropTypes.func,
  sort: PropTypes.object,
};

export default Autocomplete;
