import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { Warning } from 'phosphor-react';

import Spinner from '../../components/Spinner';

import styles from './Table.module.css';

const Table = ({ rows, columns, loading, error }) => {
  const ref = useRef(null);

  useEffect(() => {
    const tableBox = ref.current;
    if (tableBox) tableBox.scrollTop = 0;
  });

  return (
    <>
      {!loading && rows.length > 0 ? (
        <>
          <div ref={ref} className={styles.tableBox}>
            <table className={styles.table}>
              <thead>
                <tr className={styles.headerBox}>
                  {columns.map((column) => (
                    <th key={column.header}>
                      <div
                        className={`${styles.header} ${styles[column.align]}`}
                      >
                        <p>{column.header}</p>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => (
                  <tr key={row._id}>
                    {columns.map((column) => (
                      <td
                        className={styles[column.align]}
                        width={column.width}
                        key={column.header}
                      >
                        {column.render(row)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          <div className={styles.noRowsBox}>
            <span className={styles.noRowsHeader}></span>
            {error && (
              <span className={styles.errorText}>
                <Warning size={24} weight="bold" />
                <p>Error de lectura.</p>
              </span>
            )}
            {!error && rows.length > 0 && (
              <div className={styles.spinnerBox}>
                <p className={styles.spinnerText}>Cargando</p>
                <Spinner />
              </div>
            )}
            {!error && rows.length <= 0 && (
              <span className={styles.responseText}>
                No se encontraron resultados.
              </span>
            )}
          </div>
        </>
      )}
    </>
  );
};

Table.propTypes = {
  rows: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]).isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};

export default Table;
