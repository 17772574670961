import * as Yup from 'yup';

export const addSchema = Yup.object().shape({
  username: Yup.number()
    .typeError('El usuario debe ser un número.')
    .max(999999, 'El usuario es muy grande.')
    .positive('El usuario debe ser un número positivo.')
    .integer('El usuario debe ser un número entero.')
    .required('Favor de ingresar el usuario.'),
  completeName: Yup.string()
    .min(4, 'El nombre es muy corto.')
    .max(128, 'El nombre es muy largo.')
    .uppercase()
    .trim()
    .required('Favor de ingresar el nombre.'),
  password: Yup.string()
    .min(8, 'La contraseña es muy corta.')
    .max(128, 'La contraseña es muy larga.')
    .required('Favor de ingresar la contraseña.'),
  role: Yup.string().required('Favor de ingresar el rol.'),
});

export const editSchemaPwd = Yup.object().shape({
  username: Yup.number()
    .typeError('El usuario debe ser un número.')
    .max(999999, 'El usuario es muy grande.')
    .positive('El usuario debe ser un número positivo.')
    .integer('El usuario debe ser un número entero.')
    .required('Favor de ingresar el usuario.'),
  completeName: Yup.string()
    .min(4, 'El nombre es muy corto.')
    .max(128, 'El nombre es muy largo.')
    .uppercase()
    .trim()
    .required('Favor de ingresar el nombre.'),
  password: Yup.string()
    .min(8, 'La contraseña es muy corta.')
    .max(128, 'La contraseña es muy larga.')
    .required('Favor de ingresar la contraseña.'),
  role: Yup.string().required('Favor de ingresar el rol.'),
  isActive: Yup.bool().required(),
});

export const editSchemaNoPwd = Yup.object().shape({
  username: Yup.number()
    .typeError('El usuario debe ser un número.')
    .max(999999, 'El usuario es muy grande.')
    .positive('El usuario debe ser un número positivo.')
    .integer('El usuario debe ser un número entero.')
    .required('Favor de ingresar el usuario.'),
  completeName: Yup.string()
    .min(4, 'El nombre es muy corto.')
    .max(128, 'El nombre es muy largo.')
    .uppercase()
    .trim()
    .required('Favor de ingresar el nombre.'),
  role: Yup.string().required('Favor de ingresar el rol.'),
  isActive: Yup.bool().required(),
});
