import { DotsThreeVertical, Check, X } from 'phosphor-react';

import styles from '../columns.module.css';

export const addColumns = (handleEdit) => [
  {
    header: 'ID',
    data: 'staffId',
    width: '10%',
    render: (data) => <p>{data.staffId}</p>,
  },
  {
    header: 'Nombre',
    data: 'completeName',
    width: '70%',
    render: (data) => <p>{data.completeName}</p>,
  },
  {
    header: 'Activo',
    data: 'isActive',
    width: '10%',
    align: 'center',
    render: (data) =>
      data.isActive ? (
        <Check size={22} className={styles.check} weight="bold" />
      ) : (
        <X size={22} className={styles.x} weight="bold" />
      ),
  },
  {
    header: 'Editar',
    width: '10%',
    align: 'center',
    render: (data) => (
      <abbr title="Editar">
        <button className={styles.button} onClick={() => handleEdit(data)}>
          <DotsThreeVertical size={30} />
        </button>
      </abbr>
    ),
  },
];
