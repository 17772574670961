import { useEffect, useState, useCallback } from 'react';
import { Export } from 'phosphor-react';
import { toast } from 'react-toastify';

import { getDashboard, getExport } from '../../api/services/records';
import { shifts } from '../../constants/records';
import useDate from '../../context/useDate';
import exportExcel from '../../utils/exportExcel';
import useAuth from '../../context/useAuth';

import Button from '../../components/inputs/Button';
import Search from '../../components/inputs/Search';
import BarChart from '../../components/charts/BarChart';
import PieChart from '../../components/charts/PieChart';

import styles from './Dashboard.module.css';

const Dashboard = () => {
  const [shift, setShift] = useState('DIURNO');
  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [attendanceChart, setAttendanceChart] = useState({
    labels: [],
    values: [],
  });
  const [productivityChart, setProductivityChart] = useState({
    productive: 0,
    notProductive: 0,
  });
  const [activityChart, setActivityChart] = useState({
    labels: [],
    values: [],
  });

  const { user, setUser } = useAuth();
  const { date, setDate, today } = useDate();

  const getData = useCallback(
    async (date, shift) => {
      try {
        const res = await getDashboard(date, shift);
        setAttendanceChart(res.attendanceChart);
        setProductivityChart(res.productivityChart);
        setActivityChart(res.activityChart);
        setIsEmpty(res.isEmpty);
      } catch (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          setUser(null);
          toast.error('La sesión ha expirado por inactividad.');
        } else {
          toast.error('Se ha producido un error.');
        }
      }
    },
    [setUser]
  );

  useEffect(() => {
    getData(date, shift);
  }, [date, shift, getData]);

  const handleExport = async () => {
    try {
      setLoading(true);
      const res = await getExport(date);
      exportExcel(res.exportRecords, 'Reporte', `hh-micro_${date}`);
      setLoading(false);
    } catch (error) {
      if (
        error.response &&
        error.response.status &&
        error.response.status === 401
      ) {
        setUser(null);
        toast.error('La sesión ha expirado por inactividad.');
      } else {
        toast.error('Se ha producido un error.');
      }
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.searchBox}>
          <div>
            <Search
              search={date}
              handleSearch={setDate}
              type="date"
              max={today}
            />
          </div>

          <div className={styles.icons}>
            {shifts.map((obj) => {
              return (
                <abbr
                  title={`${
                    obj.value.charAt(0) + obj.value.slice(1).toLowerCase()
                  }`}
                  key={obj._id}
                >
                  <obj.icon
                    className={`${styles.icon} ${
                      obj.value === shift ? styles.selected : undefined
                    }`}
                    size={24}
                    weight="fill"
                    onClick={() => setShift(obj.value)}
                  />
                </abbr>
              );
            })}
          </div>

          <div className={styles.export}>
            <Button
              disabled={isEmpty}
              loading={loading}
              onClick={handleExport}
              startIcon={Export}
              text="Exportar"
            />
          </div>
        </div>

        <div className={styles.charts}>
          <div
            className={
              user.role === 'USER'
                ? styles.attendanceChartUser
                : styles.attendanceChart
            }
          >
            <BarChart
              labels={attendanceChart.labels}
              values={attendanceChart.values}
              bgColors={attendanceChart.bgColors}
              borderColors={attendanceChart.borderColors}
              label="Horas"
              title="Gráfico de asistencia"
            />
          </div>

          {user.role !== 'USER' && (
            <>
              <div className={styles.productivityChart}>
                <PieChart
                  labels={['Productivas', 'No productivas']}
                  values={[
                    productivityChart.productive,
                    productivityChart.notProductive,
                  ]}
                  bgColors={[
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(201, 203, 207, 0.2)',
                  ]}
                  borderColors={['rgb(75, 192, 192)', 'rgb(201, 203, 207)']}
                  title="Gráfico de productividad (Horas)"
                />
              </div>

              <div className={styles.activityChart}>
                <BarChart
                  labels={activityChart.labels}
                  values={activityChart.values}
                  bgColors={'rgba(153, 102, 255, 0.2)'}
                  borderColors={'rgb(153, 102, 255)'}
                  label="Horas"
                  title="Gráfico de actividades"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
