import PropTypes from 'prop-types';
import { X, FloppyDisk } from 'phosphor-react';

import Overlay from '../Overlay';
import Button from '../inputs/Button';

import styles from './Modal.module.css';

const Modal = ({
  children,
  disabled,
  handleClose,
  size = 'md',
  title,
  viewOnly = false,
}) => {
  return (
    <>
      <div className={styles.modal}>
        {!disabled && (
          <abbr title="Cerrar">
            <span onClick={() => handleClose()}>
              <X className={styles.close} size={24} />
            </span>
          </abbr>
        )}
        <h2 className={styles.title}>{title}</h2>
        <div
          className={`${styles[size]} ${
            viewOnly ? styles.viewOnly : undefined
          } `}
        >
          {children}
        </div>
        <div className={styles.buttons}>
          <Button
            text="Guardar"
            type="submit"
            startIcon={FloppyDisk}
            iconWeight="bold"
            loading={disabled}
            disabled={viewOnly}
          />
          <Button
            type="button"
            text="Cancelar"
            color="white"
            disabled={disabled}
            shadow={false}
            onClick={() => handleClose()}
          />
        </div>
      </div>
      <Overlay
        onClick={() => {
          if (!disabled) handleClose();
        }}
      />
    </>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  title: PropTypes.string.isRequired,
  viewOnly: PropTypes.bool,
};

export default Modal;
