import PropTypes from 'prop-types';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { CaretCircleLeft, SignOut } from 'phosphor-react';

import { logout } from '../../api/services/auth';
import { roles } from '../../constants/users';
import links from '../../constants/sidebar';
import useAuth from '../../context/useAuth';

import NavLink from './components/NavLink';

import styles from './Sidebar.module.css';

const getRole = (userRole) => {
  if (userRole === 'SUPERADMIN') return 'DESARROLLADOR';

  const role = roles.find((role) => role.value === userRole).text.toUpperCase();
  return role || 'ERROR';
};

const Sidebar = ({ open, handleOpen }) => {
  const [active, setActive] = useState(false);

  const { user, setUser } = useAuth();

  const handleActive = (link) => {
    setActive(link.to);
  };

  return (
    <>
      <aside className={open ? styles.sidebarOpen : styles.sidebarClosed}>
        <section className={styles.sidebarSection}>
          <div>
            <figure className={open ? styles.logoOpen : styles.logoClosed}>
              <CaretCircleLeft
                className={open ? styles.arrowOpen : styles.arrowClosed}
                size={40}
                onClick={() => handleOpen(!open)}
              />
              <img
                className={open ? styles.imgOpen : styles.imgClosed}
                src="./assets/LogoMmxWhite.png"
                alt="logo micrométrix"
              />
            </figure>
          </div>

          <div className={styles.linksBox}>
            <ul>
              {links.map((link) => {
                if (
                  link.roles.includes(user.role) ||
                  user.role === 'SUPERADMIN'
                ) {
                  return (
                    <abbr key={link.id} title={!open ? link.text : ''}>
                      <li key={link.id} onClick={() => handleActive(link)}>
                        <NavLink
                          active={
                            link.to === active ||
                            link.to === window.location.pathname
                          }
                          open={open}
                          text={link.text}
                          to={link.to}
                          icon={link.icon}
                        />
                      </li>
                    </abbr>
                  );
                }
                return null;
              })}
            </ul>
          </div>

          <div className={styles.sidebarFooter}>
            <div className={styles.footerWrapper}>
              <article className={open ? styles.userOpen : styles.userClosed}>
                <img src="./assets/hhAvatarDefault.svg" alt="close" />
                <p className={styles.name}>{getRole(user.role)}</p>
              </article>
              <abbr title="Salir" className={styles.iconBox}>
                <div
                  className={styles.icon}
                  onClick={async () => {
                    try {
                      setUser(null);
                      await logout();
                    } catch (error) {
                      toast.error('Se ha producido un error.');
                    }
                  }}
                >
                  <SignOut size={30} weight="bold" />
                </div>
              </abbr>
            </div>
          </div>
        </section>
      </aside>
    </>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
};

export default Sidebar;
