import { Navigate } from 'react-router-dom';

import useAuth from '../../context/useAuth';

const RoleRouter = ({ roles, children }) => {
  const { user } = useAuth();

  return (
    <>
      {roles.includes(user.role) || user.role === 'SUPERADMIN' ? (
        children
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};

export default RoleRouter;
