import * as XLSX from 'xlsx';

const exportExcel = (data, wsName, wbName) => {
  const wb = XLSX.utils.book_new();

  const ws = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, wsName);

  XLSX.writeFile(wb, `${wbName}.xlsx`);
};

export default exportExcel;
