import { Check, X, DotsThreeVertical } from 'phosphor-react';

import styles from '../columns.module.css';

export const addColumns = (handleEdit) => [
  {
    header: 'Personal',
    data: 'staff',
    width: '30%',
    render: (data) => <p>{data.staff?.completeName || '-'}</p>,
  },
  {
    header: 'Asistencia',
    data: 'attendance',
    width: '10%',
    render: (data) => <p>{data.attendance || '-'}</p>,
  },
  {
    header: 'Hora inicial',
    data: 'startTime',
    width: '10%',
    align: 'center',
    render: (data) => <p>{data.startTime || '00:00'}</p>,
  },
  {
    header: 'Hora final',
    data: 'endTime',
    width: '10%',
    align: 'center',
    render: (data) => <p>{data.endTime || '00:00'}</p>,
  },
  {
    header: 'O.P.',
    data: 'productionOrder',
    width: '10%',
    align: 'center',
    render: (data) => <p>{data.productionOrder || '-'}</p>,
  },
  {
    header: 'Cierre O.P.',
    data: 'isClose',
    width: '10%',
    align: 'center',
    render: (data) =>
      data.isClose ? (
        <Check size={22} className={styles.check} weight="bold" />
      ) : (
        <X size={22} className={styles.x} weight="bold" />
      ),
  },
  {
    header: 'Activo',
    data: 'isActive',
    width: '10%',
    align: 'center',
    render: (data) =>
      data.isActive ? (
        <Check size={22} className={styles.check} weight="bold" />
      ) : (
        <X size={22} className={styles.x} weight="bold" />
      ),
  },
  {
    header: 'Editar',
    width: '10%',
    align: 'center',
    render: (data) => (
      <abbr title="Editar">
        <button className={styles.button} onClick={() => handleEdit(data)}>
          <DotsThreeVertical size={30} />
        </button>
      </abbr>
    ),
  },
];
