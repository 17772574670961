const getStyle = (error, touched, isFocus, value, type) => {
  const isError = error && touched;
  const isFocusOrValue = isFocus || value;
  let borderStyle = 'borderDefault';
  let labelStyle = 'labelDefault';

  if (isError) {
    borderStyle = 'borderError';
  }

  if (isError && isFocusOrValue) {
    labelStyle = 'labelErrorText';
  }

  if (isError && !isFocusOrValue) {
    labelStyle = 'labelErrorNoText';
  }

  if (isError && type === 'date') {
    labelStyle = 'labelErrorText';
  }

  if (!isError && isFocusOrValue) {
    borderStyle = 'borderFocus';
    labelStyle = 'labelFocus';
  }

  if (!isError && !isFocusOrValue) {
    borderStyle = 'borderDefault';
    labelStyle = 'labelDefault';
  }

  if (!isError && !isFocusOrValue && type === 'date') {
    labelStyle = 'labelDefaultDate';
  }

  return { border: borderStyle, label: labelStyle };
};

export default getStyle;
