import styles from './NotFound.module.css';

const NotFound = () => {
  return (
    <>
      <div className={styles.box}>
        <div className={styles.code}>
          <span>4</span>
          <figure>
            <img className={styles.img} src="assets/clockDark.png" alt="0" />
          </figure>
          <span>4</span>
        </div>

        <div className={styles.messageBox}>
          <span className={styles.codeText}>
            La página que buscas no existe o ha cambiado de dirección.
          </span>
        </div>

        <span className={styles.return}>
          Hacer clic en el menú lateral para regresar.
        </span>
      </div>
    </>
  );
};

export default NotFound;
