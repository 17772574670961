import PropTypes from 'prop-types';
import { MagnifyingGlass } from 'phosphor-react';

import styles from './Search.module.css';

const Search = ({ handleSearch, search, type = 'text', max = null }) => {
  return (
    <>
      <div className={styles.searchBar}>
        <input
          type={type}
          autoCorrect="off"
          autoComplete="off"
          className={styles.searchBarInput}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Buscar..."
          value={search}
          max={max}
        />
        {type !== 'date' && (
          <div className={styles.icon}>
            <MagnifyingGlass size={24} weight="bold" />
          </div>
        )}
      </div>
    </>
  );
};

Search.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['string', 'number', 'date']),
  max: PropTypes.string,
};

export default Search;
