import instance from '../instance';

export const getPaginated = async (page, perPage, search, sort) => {
  try {
    const res = await instance.get('/records/paginated', {
      params: { page, perPage, search, sort },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getDashboard = async (date, shift) => {
  try {
    const res = await instance.get('/records/dashboard', {
      params: { date, shift },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getExport = async (date) => {
  try {
    const res = await instance.get('/records/export', {
      params: { date },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const create = async (data) => {
  try {
    const res = await instance.post('/records', data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const edit = async (data) => {
  try {
    const res = await instance.put('/records', data);
    return res.data;
  } catch (error) {
    throw error;
  }
};
