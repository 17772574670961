import instance from '../instance';

export const getPaginated = async (page, perPage, search, sort) => {
  try {
    const res = await instance.get('/users/paginated', {
      params: { page, perPage, search, sort },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const create = async (data) => {
  try {
    const res = await instance.post('/users', data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const edit = async (data) => {
  try {
    const res = await instance.put('/users', data);
    return res.data;
  } catch (error) {
    throw error;
  }
};
