import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { CaretLeft, CaretRight } from 'phosphor-react';

import Button from '../inputs/Button';

import styles from './Pagination.module.css';

const Pagination = ({
  count,
  page: selectedPage,
  perPage: selectedPerPage,
  handlePage,
  handlePerPage,
}) => {
  const totalPages = Math.ceil(count / selectedPerPage);
  const first =
    selectedPage === 1
      ? selectedPage
      : (selectedPage - 1) * selectedPerPage + 1;
  const last =
    count > selectedPerPage * selectedPage
      ? selectedPerPage * selectedPage
      : count;

  const startArray = [2, 3, 4, 5, 6, 7];

  const pageArray = [
    selectedPage - 2,
    selectedPage - 1,
    selectedPage,
    selectedPage + 1,
    selectedPage + 2,
  ];

  const endArray = [
    totalPages - 6,
    totalPages - 5,
    totalPages - 4,
    totalPages - 3,
    totalPages - 2,
    totalPages - 1,
  ];

  const perPageOptions = [10, 50, 100];

  useEffect(() => {
    if (first > count) {
      handlePage(1);
    }
  }, [first, count, handlePage]);

  const handlePageChange = (page) => {
    if (page !== selectedPage) {
      handlePage(page);
    }
  };

  const handlePerPageChange = (perPage) => {
    if (perPage !== selectedPerPage) {
      handlePerPage(perPage);
      handlePage(1);
    }
  };

  const getPagesArray = () => {
    const pages = [];

    for (let i = 0; i < totalPages; i++) {
      pages[i] = i + 1;
    }

    return pages;
  };

  return (
    <>
      <div className={styles.pagination}>
        <div className={styles.perPageBox}>
          {perPageOptions.map((option) => (
            <Button
              abbr={`Mostrar`}
              key={option}
              size="sm"
              text={option}
              color={option === selectedPerPage ? 'white' : 'dark'}
              onClick={() => handlePerPageChange(option)}
            />
          ))}
        </div>

        {count <= 0 ? (
          <>
            <div className={styles.pagesBox}>
              <p className={styles.text}>0 al 0 de 0</p>

              <div className={styles.buttonsBox}>
                <Button
                  size="sm"
                  startIcon={CaretLeft}
                  iconWeight="bold"
                  iconSize={16}
                  disabled
                />
                <Button
                  size="sm"
                  startIcon={CaretRight}
                  iconWeight="bold"
                  iconSize={16}
                  disabled
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.pagesBox}>
              <p
                className={styles.text}
              >{`Mostrando ${first} al ${last} de ${count}`}</p>

              <div className={styles.buttonsBox}>
                <Button
                  abbr="Atrás"
                  size="sm"
                  startIcon={CaretLeft}
                  iconWeight="bold"
                  iconSize={16}
                  disabled={selectedPage === 1}
                  onClick={() => handlePageChange(selectedPage - 1)}
                />

                {totalPages >= 10 ? (
                  <>
                    <Button
                      abbr="Ir"
                      size="sm"
                      text={1}
                      color={1 === selectedPage ? 'white' : 'dark'}
                      onClick={() => handlePageChange(1)}
                    />

                    {selectedPage <= 5 && (
                      <>
                        {startArray.map((page) => (
                          <Button
                            abbr="Ir"
                            key={page}
                            size="sm"
                            text={page}
                            color={page === selectedPage ? 'white' : 'dark'}
                            onClick={() => handlePageChange(page)}
                          />
                        ))}
                        <Button size="sm" text={'...'} />
                      </>
                    )}

                    {selectedPage > 5 && selectedPage < totalPages - 4 && (
                      <>
                        <Button size="sm" text={'...'} />
                        {pageArray.map((page) => (
                          <Button
                            abbr="Ir"
                            key={page}
                            size="sm"
                            text={page}
                            color={page === selectedPage ? 'white' : 'dark'}
                            onClick={() => handlePageChange(page)}
                          />
                        ))}
                        <Button size="sm" text={'...'} />
                      </>
                    )}

                    {selectedPage >= totalPages - 4 && (
                      <>
                        <Button size="sm" text={'...'} />
                        {endArray.map((page) => (
                          <Button
                            abbr="Ir"
                            key={page}
                            size="sm"
                            text={page}
                            color={page === selectedPage ? 'white' : 'dark'}
                            onClick={() => handlePageChange(page)}
                          />
                        ))}
                      </>
                    )}

                    <Button
                      abbr="Ir"
                      size="sm"
                      text={totalPages}
                      color={totalPages === selectedPage ? 'white' : 'dark'}
                      onClick={() => handlePageChange(totalPages)}
                    />
                  </>
                ) : (
                  <>
                    {getPagesArray().map((page) => (
                      <Button
                        abbr="Ir"
                        key={page}
                        size="sm"
                        text={page}
                        color={page === selectedPage ? 'white' : 'dark'}
                        onClick={() => handlePageChange(page)}
                      />
                    ))}
                  </>
                )}

                <Button
                  abbr="Siguiente"
                  size="sm"
                  startIcon={CaretRight}
                  iconWeight="bold"
                  iconSize={16}
                  disabled={selectedPage === totalPages}
                  onClick={() => handlePageChange(selectedPage + 1)}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  handlePage: PropTypes.func.isRequired,
  handlePerPage: PropTypes.func.isRequired,
};

export default Pagination;
