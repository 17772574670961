import PropTypes from 'prop-types';

import Spinner from '../../Spinner';

import styles from './Button.module.css';

const Button = ({
  color = 'dark',
  disabled,
  iconSize = 18,
  iconWeight = 'regular',
  loading,
  onClick,
  size = 'md',
  startIcon: StartIcon,
  text,
  abbr = '',
  type = 'button',
  shadow = true,
}) => {
  return (
    <>
      <abbr title={abbr}>
        <button
          className={`${styles.button} ${styles[size]} ${styles[color]} ${
            !shadow ? styles.noShadow : undefined
          }`}
          type={type}
          onClick={onClick}
          disabled={disabled || loading}
        >
          {StartIcon && !loading && (
            <StartIcon
              className={text ? styles.icon : styles.iconNoText}
              size={iconSize}
              weight={iconWeight}
            />
          )}

          {loading && (
            <div className={styles.icon}>
              <Spinner color="gray" size="sm" />
            </div>
          )}
          <p className={styles.text}>{text}</p>
        </button>
      </abbr>
    </>
  );
};

Button.propTypes = {
  color: PropTypes.oneOf(['dark', 'darklight', 'white']),
  disabled: PropTypes.bool,
  iconSize: PropTypes.number,
  iconWeight: PropTypes.oneOf(['light', 'regular', 'bold']),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  startIcon: PropTypes.object,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  abbr: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
};

export default Button;
