import PropTypes from 'prop-types';

import styles from './Spinner.module.css';

const Spinner = ({ color = 'dark', size = 'md' }) => {
  return (
    <>
      <span className={`${styles.loader} ${styles[size]} ${styles[color]}`} />
    </>
  );
};

Spinner.propTypes = {
  color: PropTypes.oneOf(['dark', 'gray', 'white']),
  size: PropTypes.oneOf(['sm', 'md']),
};

export default Spinner;
