import * as Yup from 'yup';

export const addSchema = Yup.object().shape({
  date: Yup.string()
    .min(10, 'Fecha invalida.')
    .max(10, 'Fecha invalida.')
    .required('Favor de ingresar la fecha.'),

  staff: Yup.object().when('isClose', (isClose, schema) => {
    if (!isClose) {
      return schema.required('Favor de seleccionar al personal.');
    }
  }),

  shift: Yup.object().when('isClose', (isClose, schema) => {
    if (!isClose) {
      return schema.required('Favor de seleccionar al turno.');
    }
  }),

  attendance: Yup.object().when('isClose', (isClose, schema) => {
    if (!isClose) {
      return schema.required('Favor de seleccionar la asistencia.');
    }
  }),

  startTime: Yup.object().when('attendance', (attendance, schema) => {
    if (attendance?.continue) {
      return schema.required('Favor de seleccionar la hora incial.');
    }
  }),

  endTime: Yup.object().when('attendance', (attendance, schema) => {
    if (attendance?.continue) {
      return schema.required('Favor de seleccionar la hora final.');
    }
  }),

  area: Yup.object().when('attendance', (attendance, schema) => {
    if (attendance?.continue) {
      return schema.required('Favor de seleccionar el área.');
    }
  }),

  activity: Yup.object().when('attendance', (attendance, schema) => {
    if (attendance?.continue) {
      return schema.required('Favor de seleccionar la actividad.');
    }
  }),

  line: Yup.object().when('activity', (activity, schema) => {
    if (activity?.isProductive) {
      return schema.required('Favor de seleccionar la planta.');
    }
  }),

  productionOrder: Yup.string()
    .typeError('Favor de ingresar letras o números.')
    .uppercase()
    .when('activity', (activity, schema) => {
      if (activity?.isProductive) {
        return schema.required('Favor de ingresar la orden de producción.');
      }
    })
    .when('isClose', (isClose, schema) => {
      if (isClose) {
        return schema.required('Favor de ingresar la orden de producción.');
      }
    }),

  product: Yup.object().when('activity', (activity, schema) => {
    if (activity?.isProductive) {
      return schema.required('Favor de seleccionar el producto.');
    }
  }),

  isClose: Yup.bool().required(),
});

export const editSchema = Yup.object().shape({
  date: Yup.string()
    .max(10, 'Fecha invalida.')
    .required('Favor de ingresar la fecha.'),

  staff: Yup.object()
    .when('isClose', (isClose, schema) => {
      if (!isClose) {
        return schema.required('Favor de seleccionar al personal.');
      }
    })
    .nullable(),

  shift: Yup.object()
    .when('isClose', (isClose, schema) => {
      if (!isClose) {
        return schema.required('Favor de seleccionar al turno.');
      }
    })
    .nullable(),

  attendance: Yup.object()
    .when('isClose', (isClose, schema) => {
      if (!isClose) {
        return schema.required('Favor de seleccionar la asistencia.');
      }
    })
    .nullable(),

  startTime: Yup.object()
    .when('attendance', (attendance, schema) => {
      if (attendance?.continue) {
        return schema.required('Favor de seleccionar la hora incial.');
      }
    })
    .nullable(),

  endTime: Yup.object()
    .when('attendance', (attendance, schema) => {
      if (attendance?.continue) {
        return schema.required('Favor de seleccionar la hora final.');
      }
    })
    .nullable(),

  area: Yup.object()
    .when('attendance', (attendance, schema) => {
      if (attendance?.continue) {
        return schema.required('Favor de seleccionar el área.');
      }
    })
    .nullable(),

  activity: Yup.object()
    .when('attendance', (attendance, schema) => {
      if (attendance?.continue) {
        return schema.required('Favor de seleccionar la actividad.');
      }
    })
    .nullable(),

  line: Yup.object()
    .when('activity', (activity, schema) => {
      if (activity?.isProductive) {
        return schema.required('Favor de seleccionar la planta.');
      }
    })
    .nullable(),

  productionOrder: Yup.string()
    .typeError('Favor de ingresar letras o números.')
    .uppercase()
    .when('activity', (activity, schema) => {
      if (activity?.isProductive) {
        return schema.required('Favor de ingresar la orden de producción.');
      }
    })
    .when('isClose', (isClose, schema) => {
      if (isClose) {
        return schema.required('Favor de ingresar la orden de producción.');
      }
    })
    .nullable(),

  product: Yup.object()
    .when('activity', (activity, schema) => {
      if (activity?.isProductive) {
        return schema.required('Favor de seleccionar el producto.');
      }
    })
    .nullable(true),

  isClose: Yup.bool().required(),

  isActive: Yup.bool().required(),
});
