import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './NavLink.module.css';

const NavLink = ({ active, icon: Icon, open, text, to }) => {
  return (
    <>
      <Link style={{ textDecoration: 'none' }} to={to}>
        <div className={open ? styles.link : styles.linkClose}>
          <div className={active ? styles.linkActive : styles.linkInactive}>
            <Icon className={styles.icon} size={18} weight="bold" />
            {open && <span className={styles.space}>{text}</span>}
          </div>
        </div>
      </Link>
    </>
  );
};

NavLink.propTypes = {
  active: PropTypes.bool.isRequired,
  icon: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default NavLink;
