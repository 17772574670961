import PropTypes from 'prop-types';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  zoomPlugin,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({
  labels = [],
  values = [],
  label = '',
  title = 'Gráfico',
  bgColors = 'rgba(255, 99, 132, 0.2)',
  borderColors = 'rgb(255, 99, 132)',
}) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label,
        data: values,
        backgroundColor: bgColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 20,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title,
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'y',
        },
      },
    },
  };

  return <Bar options={options} data={data} />;
};

BarChart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  values: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string,
  bgColors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string.isRequired),
    PropTypes.string,
  ]),
  borderColors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string.isRequired),
    PropTypes.string,
  ]),
};

export default BarChart;
