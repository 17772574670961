import { useState } from 'react';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { SignIn } from 'phosphor-react';

import { initialValues } from './initialValues';
import { loginSchema } from './schemas';
import { login } from '../../api/services/auth';
import useAuth from '../../context/useAuth';
import Alert from '../../components/Alert';
import TextBox from '../../components/inputs/TextBox';
import Button from '../../components/inputs/Button';

import styles from './Login.module.css';

const Login = () => {
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const { setUser } = useAuth();

  return (
    <div className={styles.background}>
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={async (values) => {
          try {
            const res = await login(values);
            const user = res.user;

            if (user) {
              setUser(user);
              navigate('/');
            }
          } catch (error) {
            if (
              error.response &&
              error.response.status &&
              error.response.status === 429
            ) {
              setError('Varios intentos fallidos, intente más tarde.');
            } else if (
              error.response &&
              error.response.status &&
              error.response.status === 401
            ) {
              setError('Credenciales invalidas.');
            } else {
              toast.error('Se ha producido un error.');
            }
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={styles.container}>
              <figure className={styles.imgContainer}>
                <img
                  className={styles.img}
                  src="assets/hhlogo.png"
                  alt="HH Micrometrix Logo"
                ></img>
              </figure>

              {error && (
                <div className={styles.error}>
                  <Alert text={error} color="error" handleError={setError} />
                </div>
              )}

              <div className={styles.inputs}>
                <TextBox name="username" label="Usuario" id="username" />
                <TextBox name="password" label="Contraseña" type="password" />
              </div>

              <div className={styles.button}>
                <Button
                  text="Entrar"
                  type="submit"
                  startIcon={SignIn}
                  iconWeight="bold"
                  size="lg"
                  loading={isSubmitting}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
