import { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Plus, FunnelSimple } from 'phosphor-react';
import { toast } from 'react-toastify';

import { addSchema, editSchema } from './schemas';
import { addInitialValues, getEditInitialValues } from './initialValues';

import { shifts, attendances, lines } from '../../constants/records';
import { getPaginated, edit, create } from '../../api/services/records';
import { getAllStaff } from '../../api/services/staff';
import { getAllAreas } from '../../api/services/areas';
import { getAllActivities } from '../../api/services/activities';
import { getAllProducts } from '../../api/services/products';
import { addColumns } from '../../utils/columns/records';
import useAuth from '../../context/useAuth';
import getTimes from '../../utils/getTimes';
import useModal from '../../hooks/useModal';

import Alert from '../../components/Alert';
import Autocomplete from '../../components/inputs/Autocomplete';
import Button from '../../components/inputs/Button';
import CheckBox from '../../components/inputs/CheckBox/CheckBox';
import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import Search from '../../components/inputs/Search';
import Toggle from '../../components/inputs/Toggle';
import TextBox from '../../components/inputs/TextBox';
import Table from '../../components/Table';

import styles from './Records.module.css';

const Records = () => {
  const { user, setUser } = useAuth();

  const {
    today,
    page,
    perPage,
    loading,
    rows,
    count,
    open,
    search,
    error,
    fetchError,
    editData,
    handleOpen,
    handleClose,
    handleEdit,
    handleRequest,
    handleSearch,
    handleSort,
    handlePage,
    handlePerPage,
    handleError,
    fetch,
  } = useModal(getPaginated, 'records', 'date');

  useEffect(() => {
    (async function initialFetch() {
      try {
        await fetch();
      } catch (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          setUser(null);
          toast.error('La sesión ha expirado por inactividad.');
        } else {
          toast.error('Se ha producido un error.');
        }
      }
    })();
  }, [fetch, setUser]);

  const resetFields = (setFieldValue, setFieldTouched, fields) => {
    fields.forEach((field) => {
      setFieldValue(field, '');
      setFieldTouched(field, false);
    });
  };

  return (
    <>
      <div className={styles.content}>
        <div className={styles.topBox}>
          <div className={styles.searchBox}>
            <Search
              search={search}
              handleSearch={handleSearch}
              type="date"
              max={today}
            />

            <abbr title="Cambiar filtro">
              <FunnelSimple
                className={styles.icon}
                size={24}
                weight="bold"
                onClick={() => handleSort({ staff: -1, createdAt: -1 })}
              />
            </abbr>
          </div>

          <Button
            onClick={() => handleOpen(true)}
            startIcon={Plus}
            iconWeight="bold"
            text="Registrar"
            disabled={user.role !== 'USER' && user.role !== 'SUPERADMIN'}
          />
        </div>

        <Table
          rows={rows}
          columns={addColumns(handleEdit)}
          error={fetchError}
          loading={loading}
        />

        <Pagination
          count={count}
          page={page}
          perPage={perPage}
          handlePage={handlePage}
          handlePerPage={handlePerPage}
        />
      </div>

      {open && (
        <Formik
          initialValues={
            editData ? getEditInitialValues(editData) : { ...addInitialValues }
          }
          validationSchema={editData ? editSchema : addSchema}
          onSubmit={async (values) => {
            const record = {
              ...values,
              user: undefined,
              attendance: values.attendance?.value || null,
              shift: values.shift?.value || null,
              staff: values.staff?._id || null,
              startTime: values.startTime?.value || null,
              endTime: values.endTime?.value || null,
              activity: values.activity?._id || null,
              area: values.area?._id || null,
              line: values.line?.value || null,
              product: values.product?._id || null,
              productionOrder: values.productionOrder || null,
            };

            try {
              const { error } = await handleRequest(record, edit, create);
              if (error) {
                handleError(error);
              } else {
                await fetch();
                handleClose();
                const message = editData
                  ? 'Registro se modificó con éxito.'
                  : 'Registro se agregó con éxito.';

                toast.success(message);
              }
            } catch (error) {
              if (
                error.response &&
                error.response.status &&
                error.response.status === 401
              ) {
                setUser(null);
                toast.error('La sesión ha expirado por inactividad.');
              } else {
                toast.error('Se ha producido un error.');
              }
            }
          }}
        >
          {({ values, isSubmitting, setFieldTouched, setFieldValue }) => (
            <Form>
              <Modal
                title={editData ? 'Editar' : 'Registrar'}
                size="lg"
                handleClose={handleClose}
                disabled={isSubmitting}
                viewOnly={
                  user.role !== 'ANALYST' &&
                  user.role !== 'SUPERADMIN' &&
                  editData
                }
              >
                <>
                  {error && (
                    <div className={styles.errorBox}>
                      <Alert
                        text={error}
                        color="error"
                        handleError={handleError}
                      />
                    </div>
                  )}

                  <TextBox name="date" label="Fecha" type="date" max={today} />

                  {!values.isClose && (
                    <>
                      <Autocomplete
                        getAll={getAllStaff}
                        query={{ isActive: true }}
                        sort={{ completeName: 1 }}
                        fieldText="completeName"
                        name="staff"
                        label="Personal"
                        size="sm"
                        path="staff"
                        handleChange={() => handleError(null)}
                      />

                      <Autocomplete
                        constantOptions={shifts}
                        fieldText="value"
                        name="shift"
                        label="Turno"
                        size="sm"
                        handleChange={() => {
                          resetFields(setFieldValue, setFieldTouched, [
                            'startTime',
                            'endTime',
                          ]);
                          handleError(null);
                        }}
                      />

                      <Autocomplete
                        constantOptions={attendances}
                        fieldText="value"
                        name="attendance"
                        label="Asistencia"
                        size="sm"
                        handleChange={() => {
                          resetFields(setFieldValue, setFieldTouched, [
                            'startTime',
                            'endTime',
                            'area',
                            'activity',
                            'line',
                            'productionOrder',
                            'product',
                          ]);
                          handleError(null);
                        }}
                      />
                    </>
                  )}

                  {values.date &&
                    values.staff &&
                    values.attendance?.continue &&
                    values.shift && (
                      <>
                        <Autocomplete
                          constantOptions={getTimes(values.shift)}
                          fieldText="value"
                          name="startTime"
                          label="Hora inicial"
                          size="sm"
                          handleChange={() => {
                            setFieldValue('endTime', '');
                            handleError(null);
                          }}
                        />

                        <Autocomplete
                          constantOptions={getTimes(
                            values.shift,
                            values.startTime
                          )}
                          fieldText="value"
                          name="endTime"
                          label="Hora final"
                          size="sm"
                          handleChange={() => handleError(null)}
                        />

                        <Autocomplete
                          constantOptions={lines}
                          fieldText="value"
                          name="line"
                          label="Planta"
                          size="sm"
                          handleChange={() => {
                            resetFields(setFieldValue, setFieldTouched, [
                              'area',
                              'activity',
                              'productionOrder',
                              'product',
                            ]);
                            handleError(null);
                          }}
                        />
                      </>
                    )}

                  {values.line && (
                    <Autocomplete
                      getAll={getAllAreas}
                      query={{ isActive: true }}
                      sort={{ areaName: 1 }}
                      fieldText="areaName"
                      name="area"
                      label="Area"
                      size="sm"
                      path="areas"
                      handleChange={() => {
                        resetFields(setFieldValue, setFieldTouched, [
                          'activity',
                          'productionOrder',
                          'product',
                        ]);
                        handleError(null);
                      }}
                    />
                  )}

                  {values.area && (
                    <>
                      <Autocomplete
                        getAll={getAllActivities}
                        query={{ isActive: true, area: values.area._id }}
                        sort={{ activityName: 1 }}
                        fieldText="activityName"
                        name="activity"
                        label="Actividad"
                        size="sm"
                        path="activities"
                        handleChange={() => handleError(null)}
                      />
                    </>
                  )}
                  {values.activity?.isProductive && (
                    <>
                      <Autocomplete
                        getAll={getAllProducts}
                        query={{ isActive: true }}
                        sort={{ productName: 1 }}
                        fieldText="productName"
                        name="product"
                        label="Producto"
                        size="sm"
                        path="products"
                        handleChange={() => handleError(null)}
                      />
                    </>
                  )}

                  {(values.activity?.isProductive || values.isClose) && (
                    <TextBox name="productionOrder" label="O.P." />
                  )}

                  <div className={styles.toggles}>
                    <CheckBox
                      name="isClose"
                      onText="Cierre O.P."
                      offText="Registro"
                      handleChange={() => {
                        resetFields(setFieldValue, setFieldTouched, [
                          'shift',
                          'attendance',
                          'staff',
                          'startTime',
                          'endTime',
                          'area',
                          'activity',
                          'line',
                          'productionOrder',
                          'product',
                        ]);
                        handleError(null);
                      }}
                    />

                    {editData && (
                      <Toggle
                        name="isActive"
                        onText="Activo"
                        offText="Inactivo "
                      />
                    )}
                  </div>
                </>
              </Modal>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default Records;
