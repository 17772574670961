import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({
  labels = [],
  values = [],
  title = 'Gráfico',
  bgColors = 'rgba(255, 99, 132, 0.2)',
  borderColors = 'rgb(255, 99, 132)',
}) => {
  const data = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: bgColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 20,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title,
      },
    },
  };

  return <Pie options={options} data={data} />;
};

PieChart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  values: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  title: PropTypes.string,
  bgColors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string.isRequired),
    PropTypes.string,
  ]),
  borderColors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string.isRequired),
    PropTypes.string,
  ]),
};

export default PieChart;
