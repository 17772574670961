import { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Plus } from 'phosphor-react';
import { toast } from 'react-toastify';

import { addSchema, editSchema } from './schemas';
import { addInitialValues } from './initialValues';
import { getPaginated, edit, create } from '../../api/services/products';
import { addColumns } from '../../utils/columns/products';
import useAuth from '../../context/useAuth';
import useModal from '../../hooks/useModal';
import Alert from '../../components/Alert';
import Button from '../../components/inputs/Button';
import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import Search from '../../components/inputs/Search';
import Toggle from '../../components/inputs/Toggle';
import TextBox from '../../components/inputs/TextBox';
import Table from '../../components/Table';

import styles from './Products.module.css';

const Products = () => {
  const { setUser } = useAuth();

  const {
    page,
    perPage,
    loading,
    rows,
    count,
    open,
    error,
    fetchError,
    search,
    editData,
    handleOpen,
    handleClose,
    handleEdit,
    handleRequest,
    handleSearch,
    handlePage,
    handlePerPage,
    handleError,
    fetch,
  } = useModal(getPaginated, 'products');

  useEffect(() => {
    (async function initialFetch() {
      try {
        await fetch();
      } catch (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          setUser(null);
          toast.error('La sesión ha expirado por inactividad.');
        } else {
          toast.error('Se ha producido un error.');
        }
      }
    })();
  }, [fetch, setUser]);

  return (
    <>
      <div className={styles.content}>
        <div className={styles.topBox}>
          <Search search={search} handleSearch={handleSearch} />

          <Button
            onClick={() => handleOpen(true)}
            startIcon={Plus}
            iconWeight="bold"
            text="Agregar"
          />
        </div>

        <Table
          rows={rows}
          columns={addColumns(handleEdit)}
          error={fetchError}
          loading={loading}
        />

        <Pagination
          count={count}
          page={page}
          perPage={perPage}
          handlePage={handlePage}
          handlePerPage={handlePerPage}
        />
      </div>

      {open && (
        <Formik
          initialValues={editData ? { ...editData } : { ...addInitialValues }}
          validationSchema={editData ? editSchema : addSchema}
          onSubmit={async (values) => {
            try {
              const { error } = await handleRequest(values, edit, create);

              if (error) {
                handleError(error);
              } else {
                await fetch({ noSearch: true });
                handleSearch('');
                handleClose();

                const message = editData
                  ? 'Producto se modificó con éxito.'
                  : 'Producto se agregó con éxito.';

                toast.success(message);
              }
            } catch (error) {
              if (
                error.response &&
                error.response.status &&
                error.response.status === 401
              ) {
                setUser(null);
                toast.error('La sesión ha expirado por inactividad.');
              } else {
                toast.error('Se ha producido un error.');
              }
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Modal
                title={editData ? 'Editar' : 'Agregar'}
                size="sm"
                handleClose={handleClose}
                disabled={isSubmitting}
              >
                {error && (
                  <Alert text={error} color="error" handleError={handleError} />
                )}

                <TextBox name="productName" label="Nombre" />

                {editData && (
                  <Toggle name="isActive" onText="Activo" offText="Inactivo" />
                )}
              </Modal>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default Products;
