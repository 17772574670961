import { Sun, Moon, DotsThreeCircle } from 'phosphor-react';

export const times = [
  { _id: 1, value: '00:00' },
  { _id: 2, value: '00:30' },
  { _id: 3, value: '01:00' },
  { _id: 4, value: '01:30' },
  { _id: 5, value: '02:00' },
  { _id: 6, value: '02:30' },
  { _id: 7, value: '03:00' },
  { _id: 8, value: '03:30' },
  { _id: 9, value: '04:00' },
  { _id: 10, value: '04:30' },
  { _id: 11, value: '05:00' },
  { _id: 12, value: '05:30' },
  { _id: 13, value: '06:00' },
  { _id: 14, value: '06:30' },
  { _id: 15, value: '07:00' },
  { _id: 16, value: '07:30' },
  { _id: 17, value: '08:00' },
  { _id: 18, value: '08:30' },
  { _id: 19, value: '09:00' },
  { _id: 20, value: '09:30' },
  { _id: 21, value: '10:00' },
  { _id: 22, value: '10:30' },
  { _id: 23, value: '11:00' },
  { _id: 24, value: '11:30' },
  { _id: 25, value: '12:00' },
  { _id: 26, value: '12:30' },
  { _id: 27, value: '13:00' },
  { _id: 28, value: '13:30' },
  { _id: 29, value: '14:00' },
  { _id: 30, value: '14:30' },
  { _id: 31, value: '15:00' },
  { _id: 32, value: '15:30' },
  { _id: 33, value: '16:00' },
  { _id: 34, value: '16:30' },
  { _id: 35, value: '17:00' },
  { _id: 36, value: '17:30' },
  { _id: 37, value: '18:00' },
  { _id: 38, value: '18:30' },
  { _id: 39, value: '19:00' },
  { _id: 40, value: '19:30' },
  { _id: 41, value: '20:00' },
  { _id: 42, value: '20:30' },
  { _id: 43, value: '21:00' },
  { _id: 44, value: '21:30' },
  { _id: 45, value: '22:00' },
  { _id: 46, value: '22:30' },
  { _id: 47, value: '23:00' },
  { _id: 48, value: '23:30' },
];

export const attendances = [
  { _id: 1, value: 'ASISTENCIA', continue: true },
  { _id: 2, value: 'PERMISO', continue: true },
  { _id: 3, value: 'INCAPACIDAD', continue: false },
  { _id: 4, value: 'VACACIONES', continue: false },
  { _id: 5, value: 'FALTA', continue: false },
];

export const shifts = [
  { _id: 1, value: 'DIURNO', icon: Sun },
  { _id: 2, value: 'NOCTURNO', icon: Moon },
  { _id: 3, value: 'OTRO', icon: DotsThreeCircle },
];

export const lines = [
  { _id: 1, value: 'MEDICAMENTOS' },
  { _id: 2, value: 'REMEDIOS HERBOLARIOS' },
  { _id: 3, value: 'SUPLEMENTOS ALIMENTICIOS' },
  { _id: 4, value: 'EMBLISTADO Y ACONDICIONAMIENTO' },
  { _id: 5, value: 'OTROS' },
];
