import PropTypes from 'prop-types';
import { useField } from 'formik';

import styles from './Toggle.module.css';

const Toggle = ({ name, onText, offText }) => {
  const [field] = useField(name);

  return (
    <>
      <abbr title="Cambiar">
        <div className={styles.inputContainer}>
          <label className={styles.toggle}>
            <input {...field} checked={field.value} type="checkbox" />
            <span className={styles.slider} />
          </label>
          <p
            className={`${styles.text} ${
              field.value ? styles.active : undefined
            }`}
          >
            {field.value ? onText : offText}
          </p>
        </div>
      </abbr>
    </>
  );
};

Toggle.propTypes = {
  name: PropTypes.string.isRequired,
  onText: PropTypes.string,
  offText: PropTypes.string,
};

export default Toggle;
