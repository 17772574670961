import instance from '../instance';

export const getAllAreas = async (query, sort) => {
  try {
    const res = await instance.get('/areas', {
      params: { query, sort },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getPaginated = async (page, perPage, search, sort) => {
  try {
    const res = await instance.get('/areas/paginated', {
      params: { page, perPage, search, sort },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const create = async (data) => {
  try {
    const res = await instance.post('/areas', data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const edit = async (data) => {
  try {
    const res = await instance.put('/areas', data);
    return res.data;
  } catch (error) {
    throw error;
  }
};
