import { createContext, useContext, useState } from 'react';

import convertToLocalTime from '../utils/convertToLocalTime';

const today = convertToLocalTime(new Date());

const DateContext = createContext({
  date: '',
  setDate: () => {},
});

export const DateProvider = ({ children }) => {
  const [date, setDate] = useState(today);

  const values = {
    date,
    setDate,
    today,
  };

  return <DateContext.Provider value={values}>{children}</DateContext.Provider>;
};

const useDate = () => {
  return useContext(DateContext);
};

export default useDate;
