import { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Plus, FunnelSimple } from 'phosphor-react';
import { toast } from 'react-toastify';

import { addSchema, editSchema } from './schemas';
import { addInitialValues } from './initialValues';
import { getPaginated, edit, create } from '../../api/services/activities';
import { getAllAreas } from '../../api/services/areas';
import { addColumns } from '../../utils/columns/activities';
import useAuth from '../../context/useAuth';
import useModal from '../../hooks/useModal';
import Alert from '../../components/Alert';
import Autocomplete from '../../components/inputs/Autocomplete';
import Button from '../../components/inputs/Button';
import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import Search from '../../components/inputs/Search';
import Toggle from '../../components/inputs/Toggle';
import CheckBox from '../../components/inputs/CheckBox/CheckBox';
import TextBox from '../../components/inputs/TextBox';
import Table from '../../components/Table';

import styles from './Activities.module.css';

const Activities = () => {
  const { setUser } = useAuth();

  const {
    page,
    perPage,
    loading,
    rows,
    count,
    open,
    error,
    fetchError,
    search,
    editData,
    handleOpen,
    handleClose,
    handleEdit,
    handleRequest,
    handleSearch,
    handleSort,
    handlePage,
    handlePerPage,
    handleError,
    fetch,
  } = useModal(getPaginated, 'activities');

  useEffect(() => {
    (async function initialFetch() {
      try {
        await fetch();
      } catch (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          setUser(null);
          toast.error('La sesión ha expirado por inactividad.');
        } else {
          toast.error('Se ha producido un error.');
        }
      }
    })();
  }, [fetch, setUser]);

  return (
    <>
      <div className={styles.content}>
        <div className={styles.topBox}>
          <div className={styles.searchBox}>
            <Search search={search} handleSearch={handleSearch} />

            <abbr title="Cambiar filtro">
              <FunnelSimple
                className={styles.icons}
                size={24}
                weight="bold"
                onClick={() => handleSort({ area: -1, activity: -1 })}
              />
            </abbr>
          </div>

          <Button
            onClick={() => handleOpen(true)}
            startIcon={Plus}
            iconWeight="bold"
            text="Agregar"
          />
        </div>

        <Table
          rows={rows}
          columns={addColumns(handleEdit)}
          error={fetchError}
          loading={loading}
        />

        <Pagination
          count={count}
          page={page}
          perPage={perPage}
          handlePage={handlePage}
          handlePerPage={handlePerPage}
        />
      </div>

      {open && (
        <Formik
          initialValues={editData ? { ...editData } : { ...addInitialValues }}
          validationSchema={editData ? editSchema : addSchema}
          onSubmit={async (values) => {
            const activity = { ...values, area: values.area._id };

            try {
              const { error } = await handleRequest(activity, edit, create);

              if (error) {
                handleError(error);
              } else {
                await fetch({ noSearch: true });
                handleSearch('');
                handleClose();

                const message = editData
                  ? 'Actividad se modificó con éxito.'
                  : 'Actividad se agregó con éxito.';

                toast.success(message);
              }
            } catch (error) {
              if (
                error.response &&
                error.response.status &&
                error.response.status === 401
              ) {
                setUser(null);
                toast.error('La sesión ha expirado por inactividad.');
              } else {
                toast.error('Se ha producido un error.');
              }
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Modal
                title={editData ? 'Editar' : 'Agregar'}
                size="sm"
                handleClose={handleClose}
                disabled={isSubmitting}
              >
                {error && (
                  <Alert text={error} color="error" handleError={handleError} />
                )}

                <TextBox name="activityName" label="Nombre" />

                <Autocomplete
                  getAll={getAllAreas}
                  query={{ isActive: true }}
                  sort={{ areaName: 1 }}
                  fieldText="areaName"
                  name="area"
                  label="Área"
                  size="sm"
                  path="areas"
                />

                <div className={styles.toggles}>
                  <CheckBox
                    name="isProductive"
                    onText="Productivo"
                    offText="No productivo"
                  />

                  {editData && (
                    <Toggle
                      name="isActive"
                      onText="Activo"
                      offText="Inactivo "
                    />
                  )}
                </div>
              </Modal>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default Activities;
