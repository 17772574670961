import * as Yup from 'yup';

export const addSchema = Yup.object().shape({
  productName: Yup.string()
    .min(4, 'El nombre es muy corto.')
    .max(128, 'El nombre es muy largo.')
    .uppercase()
    .trim()
    .required('Favor de ingresar el nombre.'),
});

export const editSchema = Yup.object().shape({
  productName: Yup.string()
    .min(4, 'El nombre es muy corto.')
    .max(128, 'El nombre es muy largo.')
    .uppercase()
    .trim()
    .required('Favor de ingresar el nombre.'),
  isActive: Yup.bool().required(),
});
