import { CaretUp } from 'phosphor-react';
import Spinner from '../../Spinner';

import styles from './OptionsList.module.css';

const OptionsList = ({
  options,
  handleSelectedOption,
  displayOptions,
  fieldText,
  handleClose,
  loading,
  size,
}) => {
  if (displayOptions && loading) {
    return (
      <>
        <div className={styles.spinner}>
          <div className={styles.spinnerBox}>
            <p>Cargando...</p>
            <Spinner size="sm" />
          </div>
        </div>
      </>
    );
  }

  if (displayOptions && options.length > 0) {
    return (
      <>
        <div className={styles.group}>
          <ul className={`${styles.optionsList} ${styles[size]}`}>
            <abbr title="Cerrar">
              <div className={styles.close} onClick={handleClose}>
                <CaretUp size={20} />
              </div>
            </abbr>

            {options.map((option, index) => {
              return (
                <li
                  key={index}
                  className={styles.option}
                  onClick={() => handleSelectedOption(index)}
                >
                  {option[fieldText]}
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
  }
};

export default OptionsList;
