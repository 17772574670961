import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  username: Yup.number()
    .typeError('El usuario debe ser un número.')
    .max(999999, 'El usuario es muy grande.')
    .positive('El usuario debe ser un número positivo.')
    .integer('El usuario debe ser un número entero.')
    .required('Favor de ingresar el usuario.'),
  password: Yup.string()
    .min(8, 'Favor de ingresar al menos 8 caracteres.')
    .max(128, 'Favor de ingresar como máximo 128 caracteres.')
    .required('Favor de ingresar la contraseña.'),
});
