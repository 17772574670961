import { BrowserRouter, Routes, Route } from 'react-router-dom';

import PrivateRouter from './routers/PrivateRouter';
import PublicRouter from './routers/PublicRouter';
import RoleRouter from './routers/RoleRouter';

import Login from '../pages/Login';
import Layout from '../components/Layout';
import Dashboard from '../pages/Dashboard';
import Areas from '../pages/Areas';
import Activities from '../pages/Activities';
import Staff from '../pages/Staff';
import Products from '../pages/Products';
import Records from '../pages/Records';
import Users from '../pages/Users';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';

const RoutesWrapper = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={
              <PublicRouter>
                <Login />
              </PublicRouter>
            }
          />

          <Route
            path="/*"
            element={
              <PrivateRouter>
                <Routes>
                  <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route
                      path="dashboard"
                      element={
                        <RoleRouter roles={['USER', 'ANALYST']}>
                          <Dashboard />
                        </RoleRouter>
                      }
                    />
                    <Route
                      path="areas"
                      element={
                        <RoleRouter roles={['ANALYST', 'ADMIN']}>
                          <Areas />
                        </RoleRouter>
                      }
                    />
                    <Route
                      path="activities"
                      element={
                        <RoleRouter roles={['ANALYST', 'ADMIN']}>
                          <Activities />
                        </RoleRouter>
                      }
                    />
                    <Route
                      path="staff"
                      element={
                        <RoleRouter roles={['ANALYST', 'ADMIN']}>
                          <Staff />
                        </RoleRouter>
                      }
                    />
                    <Route
                      path="products"
                      element={
                        <RoleRouter roles={['ANALYST', 'ADMIN']}>
                          <Products />
                        </RoleRouter>
                      }
                    />
                    <Route
                      path="records"
                      element={
                        <RoleRouter roles={['USER', 'ANALYST']}>
                          <Records />
                        </RoleRouter>
                      }
                    />
                    <Route
                      path="users"
                      element={
                        <RoleRouter roles={['ADMIN']}>
                          <Users />
                        </RoleRouter>
                      }
                    />
                    <Route path="/*" element={<NotFound />} />
                  </Route>
                </Routes>
              </PrivateRouter>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default RoutesWrapper;
