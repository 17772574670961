import useAuth from '../../context/useAuth';

import styles from './Home.module.css';

const Home = () => {
  const { user } = useAuth();

  return (
    <>
      <div className={styles.box}>
        <img
          className={styles.img}
          src="/assets/clockDark.png"
          alt="Clock logo"
        />

        <div className={styles.text}>
          <p className={styles.welcome}>Bienvenid@</p>
          <p className={styles.name}>{user.completeName}</p>
        </div>
      </div>
    </>
  );
};

export default Home;
