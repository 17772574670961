import { ToastContainer } from 'react-toastify';

import { DateProvider } from './context/useDate';
import { AuthProvider } from './context/useAuth';

import Routes from './routes';

import 'react-toastify/dist/ReactToastify.css';
import './css/reset.css';
import './css/general.css';

function App() {
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        theme="colored"
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AuthProvider>
        <DateProvider>
          <Routes />
        </DateProvider>
      </AuthProvider>
    </>
  );
}

export default App;
