import { DotsThreeVertical, Check, X } from 'phosphor-react';

import styles from '../columns.module.css';

export const addColumns = (handleEdit) => [
  {
    header: 'Actividad',
    data: 'activityName',
    width: '35%',
    render: (data) => <p>{data.activityName}</p>,
  },
  {
    header: 'Area',
    data: 'areaName',
    width: '35%',
    render: (data) => <p>{data.area.areaName}</p>,
  },
  {
    header: 'Productivo',
    data: 'isProductive',
    width: '10%',
    align: 'center',
    render: (data) =>
      data.isProductive ? (
        <Check size={22} className={styles.check} weight="bold" />
      ) : (
        <X size={22} className={styles.x} weight="bold" />
      ),
  },
  {
    header: 'Activo',
    data: 'isActive',
    width: '10%',
    align: 'center',
    render: (data) =>
      data.isActive ? (
        <Check size={22} className={styles.check} weight="bold" />
      ) : (
        <X size={22} className={styles.x} weight="bold" />
      ),
  },
  {
    header: 'Editar',
    width: '10%',
    align: 'center',
    render: (data) => (
      <abbr title="Editar">
        <button className={styles.button} onClick={() => handleEdit(data)}>
          <DotsThreeVertical size={30} />
        </button>
      </abbr>
    ),
  },
];
