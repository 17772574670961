import axiosInstance from '../instance';

export const me = async () => {
  try {
    const res = await axiosInstance.post(`/auth/me`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const login = async (credentials) => {
  try {
    const res = await axiosInstance.post(`/auth/login`, credentials);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const logout = async () => {
  try {
    const res = await axiosInstance.post(`/auth/logout`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
