import { attendances, shifts, times, lines } from '../../constants/records';

export const addInitialValues = {
  date: '',
  staff: '',
  shift: '',
  attendance: '',
  startTime: '',
  endTime: '',
  area: '',
  activity: '',
  line: '',
  productionOrder: '',
  product: '',
  isClose: false,
};

export const getEditInitialValues = (editData) => {
  const data = { ...editData };

  if (data.attendance) {
    const attendance = attendances.find(
      (attendance) => attendance.value === data.attendance
    );
    data.attendance = attendance;
  }

  if (data.shift) {
    const shift = shifts.find((shift) => shift.value === data.shift);
    data.shift = shift;
  }

  if (data.startTime) {
    const startTime = times.find((time) => time.value === data.startTime);
    data.startTime = startTime;
  }

  if (data.endTime) {
    const endTime = times.find((time) => time.value === data.endTime);
    data.endTime = endTime;
  }

  if (data.line) {
    const line = lines.find((line) => line.value === data.line);
    data.line = line;
  }

  return { ...data };
};
