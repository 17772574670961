import PropTypes from 'prop-types';
import { useFormikContext, useField } from 'formik';

import styles from './RadioButton.module.css';

const RadioButton = ({ name, text, checked, value, handleChange }) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <label className={styles.container}>
        {text}
        <input
          {...field}
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={(e) => {
            handleChange(e.target.value);
            setFieldValue(name, e.target.value);
          }}
        />
        <span className={styles.checkmark} />
      </label>
    </>
  );
};

RadioButton.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  value: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default RadioButton;
