import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';

import styles from './CheckBox.module.css';

const CheckBox = ({ name, onText, offText, handleChange }) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <div className={styles.checkWrapper}>
        <label>
          <input
            type="checkbox"
            checked={field.value}
            onChange={() => {
              setFieldValue(name, !field.value);
              handleChange();
            }}
            className={field.value ? styles.checked : undefined}
          />
        </label>
        <p
          className={`${styles.text} ${
            field.value ? styles.active : undefined
          }`}
        >
          {field.value ? onText : offText}
        </p>
      </div>
    </>
  );
};

CheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  onText: PropTypes.string,
  offText: PropTypes.string,
  handleChange: PropTypes.func,
};

export default CheckBox;
