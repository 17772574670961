import {
  DiamondsFour,
  Factory,
  Heart,
  MapPinLine,
  Pill,
  Timer,
  User,
} from 'phosphor-react';

const links = [
  {
    id: 1,
    text: 'Dashboard',
    to: '/dashboard',
    icon: DiamondsFour,
    roles: ['USER', 'ANALYST'],
  },
  {
    id: 2,
    text: 'Usuarios',
    to: '/users',
    icon: User,
    roles: ['ADMIN'],
  },
  {
    id: 3,
    text: 'Áreas',
    to: '/areas',
    icon: MapPinLine,
    roles: ['ANALYST', 'ADMIN'],
  },
  {
    id: 4,
    text: 'Actividades',
    to: 'activities',
    icon: Factory,
    roles: ['ANALYST', 'ADMIN'],
  },
  {
    id: 5,
    text: 'Personal',
    to: '/staff',
    icon: Heart,
    roles: ['ANALYST', 'ADMIN'],
  },
  {
    id: 6,
    text: 'Productos',
    to: '/products',
    icon: Pill,
    roles: ['ANALYST', 'ADMIN'],
  },
  {
    id: 7,
    text: 'Registros',
    to: '/records',
    icon: Timer,
    roles: ['USER', 'ANALYST'],
  },
];

export default links;
