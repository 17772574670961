import { times } from '../../constants/records';

const getTimes = (shift, startTime) => {
  if (shift.value === 'DIURNO' && !startTime) {
    return times.filter((time) => time._id >= 15 && time._id <= 34);
  }

  if (shift.value === 'DIURNO' && startTime) {
    return times.filter(
      (time) => time._id >= 15 && time._id <= 34 && startTime._id < time._id
    );
  }

  if (shift.value === 'NOCTURNO' && !startTime) {
    return times.filter((time) => time._id >= 27 && time._id <= 45);
  }

  if (shift.value === 'NOCTURNO' && startTime) {
    return times.filter(
      (time) => time._id >= 27 && time._id <= 45 && startTime._id < time._id
    );
  }

  if (shift.value === 'OTRO' && startTime) {
    return times.filter((time) => startTime._id < time._id);
  }

  return times;
};

export default getTimes;
