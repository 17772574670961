import PropTypes from 'prop-types';
import { X, WarningCircle } from 'phosphor-react';

import styles from './Alert.module.css';

const Alert = ({ color = 'success', handleError, text }) => {
  return (
    <>
      <div className={`${styles.alert} ${styles[color]}`}>
        <WarningCircle className={styles.icon} size={18} />
        <span className={styles.text}>{text}</span>
        <div className={styles.closeBox} onClick={() => handleError(false)}>
          <X className={styles.close} size={14} />
        </div>
      </div>
    </>
  );
};

Alert.propTypes = {
  color: PropTypes.oneOf(['success', 'error']),
  setError: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default Alert;
