import instance from '../instance';

export const getAllProducts = async (query, sort) => {
  try {
    const res = await instance.get('/products', {
      params: { query, sort },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getPaginated = async (page, perPage, search, sort) => {
  try {
    const res = await instance.get('/products/paginated', {
      params: { page, perPage, search, sort },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const create = async (data) => {
  try {
    const res = await instance.post('/products', data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const edit = async (data) => {
  try {
    const res = await instance.put('/products', data);
    return res.data;
  } catch (error) {
    throw error;
  }
};
