import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Plus } from 'phosphor-react';
import { toast } from 'react-toastify';

import { addSchema, editSchemaPwd, editSchemaNoPwd } from './schemas';
import { addInitialValues } from './initialValues';
import { getPaginated, edit, create } from '../../api/services/users';
import { addColumns } from '../../utils/columns/users';
import { roles } from '../../constants/users';
import useAuth from '../../context/useAuth';
import useModal from '../../hooks/useModal';

import Alert from '../../components/Alert';
import Button from '../../components/inputs/Button';
import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import Search from '../../components/inputs/Search';
import Toggle from '../../components/inputs/Toggle';
import TextBox from '../../components/inputs/TextBox';
import RadioButton from '../../components/inputs/RadioButton';
import Table from '../../components/Table';

import styles from './Users.module.css';

const Users = () => {
  const [changePassword, setChangePassword] = useState(false);
  const [selected, setSelected] = useState('USER');

  const { setUser } = useAuth();

  const {
    page,
    perPage,
    loading,
    rows,
    count,
    open,
    error,
    fetchError,
    search,
    editData,
    handleOpen,
    handleClose,
    handleEditData,
    handleRequest,
    handleSearch,
    handlePage,
    handlePerPage,
    handleError,
    fetch,
  } = useModal(getPaginated, 'users');

  useEffect(() => {
    (async function initialFetch() {
      try {
        await fetch();
      } catch (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          setUser(null);
          toast.error('La sesión ha expirado por inactividad.');
        } else {
          toast.error('Se ha producido un error.');
        }
      }
    })();
  }, [fetch, setUser]);

  const handleEdit = (data) => {
    handleEditData(data);
    setSelected(data.role);
    handleOpen();
  };

  return (
    <>
      <div className={styles.content}>
        <div className={styles.topBox}>
          <Search search={search} handleSearch={handleSearch} />

          <Button
            onClick={() => handleOpen(true)}
            startIcon={Plus}
            iconWeight="bold"
            text="Agregar"
          />
        </div>

        <Table
          rows={rows}
          columns={addColumns(handleEdit)}
          error={fetchError}
          loading={loading}
        />

        <Pagination
          count={count}
          page={page}
          perPage={perPage}
          handlePage={handlePage}
          handlePerPage={handlePerPage}
        />
      </div>

      {open && (
        <Formik
          initialValues={
            editData ? { ...editData, password: '' } : { ...addInitialValues }
          }
          validationSchema={
            editData
              ? changePassword
                ? editSchemaPwd
                : editSchemaNoPwd
              : addSchema
          }
          onSubmit={async (values) => {
            if (!values.password && editData && !changePassword) {
              values.password = undefined;
            }

            try {
              const { error } = await handleRequest(values, edit, create);

              if (error) {
                handleError(error);
              } else {
                await fetch({ noSearch: true });
                handleSearch('');
                setChangePassword(false);
                handleClose();

                const message = editData
                  ? 'Usuario se modificó con éxito.'
                  : 'Usuario se agregó con éxito.';

                toast.success(message);
              }
            } catch (error) {
              if (
                error.response &&
                error.response.status &&
                error.response.status === 401
              ) {
                setUser(null);
                toast.error('La sesión ha expirado por inactividad.');
              } else {
                toast.error('Se ha producido un error.');
              }
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Modal
                title={editData ? 'Editar' : 'Agregar'}
                size="sm"
                handleClose={() => {
                  setChangePassword(false);
                  handleClose();
                }}
                disabled={isSubmitting}
              >
                {error && (
                  <Alert text={error} color="error" handleError={handleError} />
                )}

                <TextBox name="username" label="Usuario" />

                <TextBox name="completeName" label="Nombre" />

                {(changePassword || !editData) && (
                  <TextBox name="password" label="Contraseña" type="password" />
                )}

                {editData && (
                  <div className={styles.changePassword}>
                    <Button
                      color="white"
                      size="sm"
                      text={
                        changePassword
                          ? 'Cancelar cambio'
                          : 'Cambiar contraseña'
                      }
                      shadow={false}
                      onClick={() => setChangePassword(!changePassword)}
                    />
                  </div>
                )}

                <div>
                  <div className={styles.roles}>
                    <p className={styles.roleTitle}>Rol:</p>
                    {roles.map((role) => (
                      <RadioButton
                        key={role.value}
                        name="role"
                        text={role.text}
                        value={role.value}
                        checked={selected === role.value}
                        handleChange={setSelected}
                      />
                    ))}
                  </div>

                  {editData && (
                    <Toggle
                      name="isActive"
                      onText="Activo"
                      offText="Inactivo"
                    />
                  )}
                </div>
              </Modal>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default Users;
