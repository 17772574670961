import * as Yup from 'yup';

export const addSchema = Yup.object().shape({
  staffId: Yup.number()
    .typeError('El ID debe ser un número.')
    .max(999999, 'El ID es muy grande.')
    .positive('El ID debe ser un número positivo.')
    .integer('El ID debe ser un número entero.')
    .required('Favor de ingresar el ID.'),
  completeName: Yup.string()
    .min(4, 'El nombre es muy corto.')
    .max(128, 'El nombre es muy largo.')
    .uppercase()
    .trim()
    .required('Favor de ingresar el nombre.'),
});

export const editSchema = Yup.object().shape({
  staffId: Yup.number()
    .typeError('El ID debe ser un número.')
    .max(999999, 'El ID es muy grande.')
    .positive('El ID debe ser un número positivo.')
    .integer('El ID debe ser un número entero.')
    .required('Favor de ingresar el ID.'),
  completeName: Yup.string()
    .min(4, 'El nombre es muy corto.')
    .max(128, 'El nombre es muy largo.')
    .uppercase()
    .trim()
    .required('Favor de ingresar el nombre.'),
  isActive: Yup.bool().required(),
});
