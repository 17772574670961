import { Navigate } from 'react-router-dom';

import useAuth from '../../context/useAuth';

import Spinner from '../../components/Spinner';

import styles from './Router.module.css';

const PrivateRouter = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <>
        <div className={styles.loader}>
          <p className={styles.loaderText}>Cargando</p>
          <Spinner />
        </div>
      </>
    );
  }

  return <>{user ? children : <Navigate to="/login" />}</>;
};

export default PrivateRouter;
